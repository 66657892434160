import { People } from './people.types';

export type CustomLabelField = {
  /** Custom label field id */
  field_id?: string;

  /** Custom label field option ids */
  option_ids?: string[];
};

export type Metadata =
  | { selectionOptions: { listOptions: { maxEntries: number } } }
  | { userOptions: { listOptions: { maxEntries: number } } };

export enum CustomLabelType {
  OPTIONS_LIST = 'OPTIONS_LIST',
  PERSON = 'PERSON',
}

export enum PermissionEntryType {
  USER = 'USER',
  GROUP = 'GROUP',
  DOMAIN = 'DOMAIN',
}

export type Entry = {
  /** Entry ID */
  id: number;

  /** Entry type, USER or GROUP */
  type: PermissionEntryType;

  /** Entry email */
  value: string;

  /** Entry creation date */
  created_at: string;

  /** Entry update date */
  updated_at: string;
};

export type Permission = {
  /** Permission ID */
  id: string;

  /** Permission type, USER or GROUP */
  type?: PermissionEntryType;

  /** Permission email value for GROUP EDIT */
  value?: string;

  /** Permission entries, USER or GROUP */
  entries: Entry[];

  /** Permission people */
  people?: People;

  /** Permission creation date */
  created_at: string;

  /** Permission update date */
  updated_at: string;
};

export type CustomLabelApi = {
  /** Custom label id */
  id: string | undefined;

  /** Custom label name */
  name: string;

  /** Custom label type */
  type: CustomLabelType;

  /** Custom label gsheet file id */
  gsheetFileId: string | null;

  /** Custom label permissions */
  permission: Permission[];

  /** Custom label field */
  labelField: {
    name: string;
    custom_metadata: Metadata;
    options?: LabelOption[];
  };
};

export type CustomLabelInput = {
  /** Custom label id */
  id: string | undefined;

  /** Custom label name */
  name: string;

  /** Custom label multiple selection */
  allowMultiSelect: boolean;

  /** Custom label type */
  type: CustomLabelType;

  /** Custom label gsheet file id */
  gsheetId: string | null;

  /** Custom label permissions */
  permissions: {
    email: string;
    fullname: string;
    type?: PermissionEntryType;
  }[];
};

export type CustomLabelInputPermissions = {
  /** Custom label id */
  id: string | undefined;

  /** Custom label name */
  name: string;

  /** Custom label multiple selection */
  allowMultiSelect: boolean;

  /** Custom label type */
  type: CustomLabelType;

  /** Custom label gsheet file id */
  gsheetId: string | null;

  /** Custom label permissions */
  permissions: {
    value: string;
    type: PermissionEntryType;
  }[];
};

export type LabelOption = {
  /** Option's concatened ID with Label ID */
  id: string;

  /** Google Label Option ID */
  option_id: string;

  /** Option's name value */
  name: string;

  /** Option's description */
  description: string | null;

  /** Option's type */
  type: string;
};

export type CustomLabel = {
  /** Custom label id */
  id: string | undefined;

  /** Custom label name */
  name: string;

  /** Custom label multiple selection */
  allowMultiSelect: boolean;

  /** Custom label type */
  type: string;

  /** Custom label gsheet file url */
  gsheetUrl: string | null;

  /** Custom label permissions */
  permissions: Permission[];

  /** Custom label field */
  label_field: {
    name: string;
    custom_metadata: Metadata;
    field_id?: string;
    id?: string;
  };

  /** Custom label options */
  options?: LabelOption[];
};
