export enum OnBoardingState {
  /** Nothing has been started. */
  NOT_STARTED = 'NOT_STARTED',

  /** Service account creation. */
  DEDICATED_ACCOUNT_CREATION = 'DEDICATED_ACCOUNT_CREATION',

  /** Service account license assignment. */
  DEDICATED_ACCOUNT_LICENSE_ASSIGNMENT = 'DEDICATED_ACCOUNT_LICENSE_ASSIGNMENT',

  /** Service account permissions creation. */
  DEDICATED_ACCOUNT_PERMISSIONS_CREATION = 'DEDICATED_ACCOUNT_PERMISSIONS_CREATION',

  /** Ask admin to login onto the newly service account. */
  DEDICATED_ACCOUNT_LOGIN = 'DEDICATED_ACCOUNT_LOGIN',

  /** Google drive labels creation. */
  DRIVE_LABELS_CREATION = 'DRIVE_LABELS_CREATION',

  /** Google drive labels publishing. */
  DRIVE_LABELS_PUBLISHING = 'DRIVE_LABELS_PUBLISHING',

  /** Google drive labels updating permissions. */
  DRIVE_LABELS_PERMISSIONS = 'DRIVE_LABELS_PERMISSIONS',

  /** Selection of the drives on which to set the service account. */
  DRIVE_SELECTION = 'DRIVE_SELECTION',

  /** Install Overlayer chrome extension on the domain. */
  OVERLAYER_EXTENSION_INSTALLATION = 'OVERLAYER_EXTENSION_INSTALLATION',

  /** Finished */
  FINISHED = 'FINISHED',
}

export const SERVICES_PRIVILEGES_LINK =
  'https://docs.google.com/document/d/1mmeS40AuviARYs2CAJLYF1f3UdxXUCOjT7waNJA4I7s/edit?usp=sharing';
export const CONTRIBUTOR_ACCESS_LINK =
  'https://docs.google.com/document/d/14PBXiwX99enCela8pi1gSUfHCnbR0fMhMhAMY-qFkVA/edit?tab=t.0';
export const INSTALL_EXTENSION_LINK =
  'https://docs.google.com/document/d/1mUcFRDrGzJb8SGcNkwTxCLXHG81qKC2v_BOwFn1hM2Y/edit?usp=sharing';

export const ITEMS = [
  { label: 'Login with G-Workspace admin' },
  { label: 'Connect with Tech User' },
  { label: 'Install on Shared Drives' },
];

export const DEFAULT_EMAIL = 'ged';

// Create a mapping object
export const ONBOARDING_STATE_TO_STEP: { [key in OnBoardingState]: number } = {
  [OnBoardingState.NOT_STARTED]: 0,
  [OnBoardingState.DEDICATED_ACCOUNT_CREATION]: 1,
  [OnBoardingState.DEDICATED_ACCOUNT_LICENSE_ASSIGNMENT]: 4,
  [OnBoardingState.DEDICATED_ACCOUNT_PERMISSIONS_CREATION]: 4,
  [OnBoardingState.DEDICATED_ACCOUNT_LOGIN]: 4,
  [OnBoardingState.DRIVE_LABELS_CREATION]: 2,
  [OnBoardingState.DRIVE_LABELS_PUBLISHING]: 4,
  [OnBoardingState.DRIVE_LABELS_PERMISSIONS]: 4,
  [OnBoardingState.DRIVE_SELECTION]: 2,
  [OnBoardingState.OVERLAYER_EXTENSION_INSTALLATION]: 3,
  [OnBoardingState.FINISHED]: 3,
};

export const LOADING_ONBOARDING_STATES = [
  OnBoardingState.DEDICATED_ACCOUNT_LOGIN,
  OnBoardingState.DEDICATED_ACCOUNT_PERMISSIONS_CREATION,
];
