import { File } from './file.types';
import { User } from './user.types';
import { WorkflowState } from './workflow.types';

// To commonize with other types
export type WorkflowTemplatesResponse = WorkflowTemplate[];

export interface WorkflowInstanceResponse {
  instance: WorkflowInstance;
}

export interface WorkflowInstancesResponse {
  workflow_instances: WorkflowInstance[];
}

export interface WorkflowTemplateResponse {
  template: WorkflowTemplate;
}

export interface WorkflowCanBeStartedResponse {
  can_started: boolean;
}

export interface WorkflowCanContinueResponse {
  can_approve: boolean;
  can_reject: boolean;
  can_acknowledge: boolean;
}

export interface WorkflowTemplate {
  id: number;
  name: string;
  is_enabled: boolean;
  whitelisted_drives: string[];
  blacklisted_drives: string[];
  steps: WorkflowStep[];
  startup_role: StartupRole;
  created_at: string;
  updated_at: string;
}

export interface workflowUserAction {
  id: number;
  user: User;
  type: string;
  at: string;
  created_at: string;
  updated_at: string;
}

export interface stepTemplate {
  id: number;
  name: string;
  index: number;
  type: string;
  execution_type: string;
  config: any;
  created_at: string;
  updated_at: string;
}

export enum WorkflowStepState {
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export interface WorkflowStep {
  id: number;
  state: WorkflowStepState;
  template: stepTemplate;
  user_actions: workflowUserAction[];
  created_at: string;
  updated_at: string;
  state_modified_at?: string;
}

export interface StartupRole {
  id: number;
  entries: StartupRoleEntry[];
  created_at: string;
  updated_at: string;
}

export interface StartupRoleEntry {
  id: number;
  type: string;
  value: string;
  created_at: string;
  updated_at: string;
}

export interface WorkflowInstance {
  id: number;
  state: WorkflowState;
  version_major: number;
  version_minor: number;
  started_by: User;
  file: File;
  template: WorkflowTemplate;
  steps: WorkflowStep[];
  finished_by: User;
  pdf_file_id: string | null;
  file_id: string;
  created_at: string;
  updated_at: string;
  finished_at?: string;
}
